

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class ManageCaregiversService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getNotificationList(data: any) {
        return this.http.post(this.url + 'notification/getNotifications', data);
    }

    createNotification(data: any) {
        return this.http.post(this.url + 'notification/saveNotification', data);
    }

    deleteNotification(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'notification/deleteNotification', httpOptions);
    }

    updateNotification(data : any) {
        return this.http.post(this.url + "notification/updateNotification", data);
    }

    updatebusiness(data: any, id: any) {
        return this.http.put(this.url + "api/user/update-user/" + id, data);
      }
      
      


    approveCaregiver(data:any){
        return this.http.post(this.url + "user/approve-caregiver", data);
    }


    deleteService(data:any){
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'admins/delete-preventMeasure', httpOptions);
    }

    getServiceBySearch(data:any){
        return this.http.post(this.url + 'admins/search-prevent', data);
    }

    setStatus(data:any){
        return this.http.post(this.url + "user/change-status", data);
    }
    
  addPreventive(data: any) {
    return this.http.post(this.url + 'admins/add-preventMeasure', data);
  }

  editService(data:any){
    return this.http.post(this.url + 'admins/edit-preventMeasure', data);
}

getRaisedList(page: number, limit: number, data: any) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
  
    return this.http.get(this.url + 'api/ticket/list', { params: params, ...data });
  }


getEnableDisable(data:any) {
    return this.http.post(this.url + 'admins/enable-measure', data);
}
}