import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  },
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "change-password", component: EditProfileComponent },

      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },



      {
        path: "analytics-dashboard",
        loadChildren: () =>
        import("../app/dashboard/dashboard.module").then(
          (m) => m.DashboardModule
        )
      },

      {
        path: "manage-serviceProviders",
        loadChildren: () =>
        import("../app/views/manage-providers/manage-providers.module").then(
          (m) => m.ManageClientsModule
        )

        
      },

      {
        path: "manage-organisers",
        loadChildren: () =>
        import("../app/views/manage-categories/manage-categories.module").then(
          (m) => m.ManageCaregiversModule
        )
      },

      {
        path: "manage-discounts",
        loadChildren: () =>
        import("../app/views/generate-code/generate-code.module").then(
          (m) => m.GenerateCodeModule
        )
      },
      {
        path: "manage-serviceCategories",
        loadChildren: () =>
        import("../app/views/manage-categories/manage-categories.module").then(
          (m) => m.ManageCaregiversModule
        )
      },
      {
        path: "manage-categories",
        loadChildren: () =>
        import("../app/views/manage-servicecategories/manage-servicecategories.module").then(
          (m) => m.ManageDiseasesModule
        )
      },
      {
        path: "manage-subCategories",
        loadChildren: () =>
        import("../app/views/manage-sub/manage-sub.module").then(
          (m) => m.ManageSubModule
        )
      },

      {
        path: "manage-raisedTicket",
        loadChildren: () =>
        import("../app/views/manage-raised/manage-raised.module").then(
          (m) => m.ManageCaregiversModule
        )
      },


    ],
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
