

  <div class="sidebar" style="background:linear-gradient(to bottom, rgba(41, 234, 165, 0.5),rgba(23, 186, 174, 0.5)
 );border: none;border-radius: 23px;">


  
  <div class="logo mt-2"><img src="../../../assets/images/NegativeEventutti 1.png" alt="logo" style=" object-fit: contain;height: 74px;margin-top: 20px;margin-bottom: 35px;    width: 203px;
    margin-left: 35px"></div>

  <div class="header-links">
    <a routerLink="/analytics-dashboard" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
      <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/little/feedback.svg" alt="" height="24" width="24">
      </span>
      <span class="link-text">Reporting</span>
  </a>

  
  <a routerLink="/manage-serviceProviders" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
    <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/little/About.svg" alt="" height="24" width="24">
    </span>
    <span class="link-text">Service Providers</span>
</a>

<a routerLink="/manage-organisers" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/little/About.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Event Creator</span>
</a>


<a routerLink="/manage-discounts" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/little/About.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Generate Discounts</span>
</a>



  


<a routerLink="/manage-categories" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/little/Usage information.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Services</span>
</a>

<a routerLink="/manage-subCategories" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/little/Usage information.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Service Categories</span>
</a>

<a routerLink="/manage-raisedTicket" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/little/Manage Hospital.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Raised Ticket</span>
</a>

<a routerLink="/edit-profile" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/little/Chnage Password Black.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Change Password</span>
</a>


  <a (click)="openModal(template)" routerLinkActive="active-link"  id="logout" style="color: rgba(96, 111, 140, 1);">
      <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/little/Log Out Black.svg" alt="" height="24" width="24">
      </span>
      <span class="link-text">Logout</span>
  </a>
   
  
<ng-template #template>


  <div class="modal-body text-center delete-modal align-items-center" style="background-color: #a3e6de;border-radius: 20px;">
    
      <img  src="../../../../../assets/images/create-successfull-img.svg" alt="">
      <p style="color: black;">Are you sure you <br>want to logout?</p>
      <div class="modal-btn d-flex justify-content-around">
          <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
          <button class="btn btn-red" (click)="confirm()" style="color: black;">Yes</button>
      </div>
  </div>
</ng-template>

  </div>
</div>



