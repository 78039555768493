

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class ManageClientsService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getNotificationList(data: any) {
        return this.http.post(this.url + 'notification/getNotifications', data);
    }

    getProviders(data: any, pageNumber, limit) {
        const url = `${this.url}api/user/service-providers-list?page=${pageNumber}&limit=${limit}`;
        return this.http.get(url, data);
    }

    getProvidersData(id:any) {
        const url = `${this.url}api/user/user-profile?id=${id}`;
        return this.http.get(url);
    }
    

    createNotification(data: any) {
        return this.http.post(this.url + 'notification/saveNotification', data);
    }

    deleteNotification(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'notification/deleteNotification', httpOptions);
    }

    updateNotification(data: any) {
        return this.http.post(this.url + "notification/updateNotification", data);
    }

    providerApprove(id: any, data: any) {
        return this.http.put(this.url + "api/user/update-user/" + id, data);
    }



}