<div id="forgot-password">
  <div class="container-fluid">
      <div class="row">
        
          <div class="logo"><img src="../../../../assets/images/NegativeEventutti 1.png" alt="logo"></div>
          <div class="col-md-12">
              <div class="login-field">
                  <div class="login-box">
                      <h3>Reset your Password</h3>
                      <p class="mb-3">Enter the email address associated with your account and we will send you the
                          new
                          password.</p>
                      <form [formGroup]="resetForm" (submit)="resetPassword()">
                          <div class="form-group">
                              <label for="email">Email Address</label>
                              <span class="input-icon"><img src="../../../../assets/icons/email.svg" alt="" style="margin-top: -11px;"></span>
                              <input type="email" class="form-control" placeholder="Email" id="email" formControlName="email">
                              <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                                  <span *ngIf="f.email.errors.required">
                                      Email is required.
                                  </span>
                                  <span *ngIf="f.email.errors.email">
                                      Invalid email.
                                  </span>
                              </div>
                          </div>
                          <div class="back-signin" routerLink="/">Back to Sign In</div>
                          <button type="submit" class="btn btn-primary" style="background-color: #18bdad;">Reset
                              Password</button>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>



<ng-template #template>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal">
      <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
   
    <p>Password sent to your<br />email address</p>
    <div class="modal-btn">
      
      <button class="btn btn-cancel btn-primary" (click)="OkPress()" style="background-color: #346ef6;">OK</button>
     
    </div>
  </div>
</ng-template>
