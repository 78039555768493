import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageDiseasesService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getInterests(data: any) {
        return this.http.post(this.url + 'category/list', data);
    }

    createInterests(data: any) {
        return this.http.post(this.url + 'category/create', data);
    }

    updateInterest(data: any) {
        return this.http.patch(this.url + 'category/update', data);
    }

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    importInterest(data: any) {
        return this.http.post(this.url + 'import/areaOfInterest', data);
    }



    createCategory(data: any) {
        return this.http.post(this.url + 'api/ad-on-service/add', data);
    }


    deleteCategory(data: any, id: any) {
        const httpOptions = {
          headers: new HttpHeaders({}),
          body: data
        };
        return this.http.delete(this.url + 'api/ad-on-service/delete/' + id, httpOptions);
      }

    editCategory(id: string, data: any) {
        const url = `${this.url}api/ad-on-service/update/${id}`;
        return this.http.put(url, data);
    }
    

    getCategoryapi(data: any) {
        return this.http.post(this.url + 'admin/category/get', data);
    }

    getCategoryList(data: any) {
        return this.http.get(this.url + 'api/ad-on-service/list', data);
    }

    uploadImage(data: any) {
        return this.http.post(this.url + 'api/file/upload', data);
    }

   

    deleteDisease(data: any) {
      
        return this.http.post(this.url + 'disease/delete', data);
    }

    diseaseEdit(data: any) {
        return this.http.post(this.url + 'disease/edit', data);
    }

}