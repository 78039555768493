import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  tabData = [
    { name: 'Andrew Nathan', email: 'andrewnathan@gmail.com', label: 'Head Coach', date: '2023-09-12', profilePicture: 'path_to_image1', description: 'PGA CUP FootBall Match', experience: '3 years' },
    { name: 'Devon Lane', email: 'devonlane@gmail.com', label: 'Assistant Coach', date: '2023-07-05', profilePicture: 'path_to_image1', description: 'Lone Star Youth FootBall', experience: '4 years' },
    { name: 'Eleanor Pena', email: 'eleanorpena@gmail.com', label: 'Head Negotiator', date: '2023-10-14', profilePicture: 'path_to_image1', description: 'SuperStar FootBall League', experience: '5 years' },
    { name: 'Gunesko Cabiyik', email: 'guneskocabiyik@gmail.com', label: 'Waiver Wizard', date: '2023-09-11', profilePicture: 'path_to_image1', description: 'Elite Gridiron League', experience: '3 years' },
    { name: 'Bessie Cooper', email: 'bessiecooper@gmail.com', label: 'Squad Member', date: '2023-09-08', profilePicture: 'path_to_image1', description: 'Stellar FootBall ShowCase', experience: '2.5 years' },
    { name: 'Marvin McKinney', email: 'marvinmckinney@gmail.com', label: 'Anonymous users', date: '2023-10-10', profilePicture: 'path_to_image1', description: 'PowerPlay FootBall League', experience: '2.9 years' },
  ];

  items: any[] = [
    { id: 1, name: 'PGA CUP Football Match' },
    { id: 2, name: 'Golden State Football League' },
    { id: 3, name: 'Lone Star Youth Football' },
    { id: 4, name: 'Golden State Football League' },
    // Add more items as needed
  ];


  currentType: string = '';
  userData: any[] = [];
  public ownerHighlighted = false;
  public contractorHighlighted = false;
  public canvas: any;
  public ctx: any;
  public chart: any;
  dashboardData: any = [];
  dashboardAllData: any;
  graphData: any;
  months: number = 6;
  currentUser: any;
  analyticsData: any;
  finalCount: any;
  clientCounts: number = 0;
  careGiverCounts: number = 0;
  bookingCount: number = 0;
  incomeCount: number = 0;
  @ViewChild("template2") template2: TemplateRef<any>;
  @ViewChild("templateImage1") templateImage1: TemplateRef<any>;


  constructor(
    private _header: HeaderService,
    private dashboardService: DashboardService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private modalService: BsModalService,

  ) { }


  ngOnInit(): void {
    this.onClickHomeOwner('owner')
    this._header.welcomeUserMsg.next(true);
    this.currentUser = this.authService.currentUserValue;
    this.route.queryParams.subscribe(params => {
      let paramPage = params['page'];
      this.page = parseInt(params['page']);
      if (typeof paramPage == 'undefined') {
        this.page = 1;
      } else {
        this.page = parseInt(paramPage);
      }
    });

    if (this.page) {
      if (this.contractor) {
        this.contractorCurrentPage = this.page

      } else {
        this.ownerCurrentPage = this.page
      }
    }
    this.getCount();
    this.selectedTabData = this.tabData.find(tab => tab.name === 'Andrew Nathan');

  }

  imgURL: any;
  reset() {
    this.imgURL = "../../../assets/icons/img-thumb.svg";

  }

  readOnly: any = true;
  removeRead() {
    this.readOnly = false;
  }

  toggleDropdown() {
    let dropdownContent = document.getElementById("dropdownContent");
    if (dropdownContent.style.display === "block") {
      dropdownContent.style.display = "none";
    } else {
      dropdownContent.style.display = "block";
    }
  }

  openPopupforDetails(template: any) {
    this.modalRef = this.modalService.show(template);
  }

  selectedItem: any | null = null;

  // Function to handle item selection
  selectItem(item: any) {
    this.selectedItem = item; // Update the selected item
  }


  selectedTabData: any;
  selTab: any = 'Andrew Nathan';
  setupTabs(): void {
    const tabs = document.querySelectorAll('.tab');
    const tabContents = document.querySelectorAll('.tab-content');

    tabs.forEach((tab, index) => {
      tab.addEventListener('click', () => {
        if (tabContents[index] && tabContents[index].id) {
          this.showTabContent(tabContents[index].id)
        }
      });
    });
  }

  showTabContent(tabType: string): void {
    this.selTab = tabType;
    this.selectedTabData = this.tabData.find(tab => tab.name === tabType);
  }

  ViewUploadImage() {
    this.openModal(this.templateImage1);
  }

  searchText: any;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  dateInput: ElementRef;
  onSubmit(f: any) {
    //intentionally left space for future use

  }

  checkEmpty(event: any) {
    //intentionally left space for future use
  }

  modalRef: BsModalRef;
  owner: any;
  user: any;
  selectedOwner: any;
  createService(template2: TemplateRef<any>, owner: any) {
    this.selectedOwner = owner;
    this.modalRef = this.modalService.show(template2, { class: 'modal-lg' });
  }

  homeOwnersData: any;
  page: number;
  ownersData: any
  contractorData: any;
  notFound: boolean = false;
  itemsPerPage: number = 10;
  totalCount: number;
  currentPage: number = 1;
  contractorCurrentPage: number = 1;
  ownerCurrentPage: number = 1;

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }

  registeredhomeowners: number;
  registeredcontractors: number;
  registeredcontractorsList: any[] = [];
  registeredhomeownerList: any[] = [];
  contractor: boolean = false;
  typeUser: any
  promotionServicesOption: boolean = false;
  subscriptionOption: boolean = false;
  contractorCount: any;
  onClickContractor(type: any) {

  }

  convertDateTimeFormat(date: any) {
    const formattedDate = this.datepipe.transform(new Date(date), 'MMM dd, yyyy');
    const formattedDateTime = `${formattedDate}`;
    return formattedDateTime;
  }

  shouldDisplayAddressOnNextLine(address: string): string {
    if (address) {
      const words = address.split(' ');
      if (words.length > 10) {
        return words.join('<br>');
      }
    }
    return address ? address : 'N/A';
  }
  pageChangedOwner(event: PageChangedEvent): void {
    this.ownerCurrentPage = event.page;
    this.onClickHomeOwner('owner');
    (<HTMLInputElement>document.getElementById("search")).value = '';
    window.scrollTo(0, 0);

  }

  pageChangedContract(event: PageChangedEvent): void {
    console.log('event', event)
    this.contractorCurrentPage = event.page;
    this.onClickContractor('contractor');
    (<HTMLInputElement>document.getElementById("search")).value = '';
    window.scrollTo(0, 0);

  }
  ownerCount: any;
  onClickHomeOwner(type: any) {
    //intentionally left space for future use

  }
  registeredOrganisers:any;
  registeredProviders:any;
  getCount() {
    this.dashboardService.getDashboardCountofhome().subscribe((res: any) => {
      if (res.type == "success") {
        this.registeredOrganisers = res.data.eventOrgCount;
        this.registeredProviders = res.data.serviceProviderCount;

      }
    })
  }




  getCountofhome() {
    //   this.dashboardService.getDashboardCountofhome().subscribe((res: any) => {
    //     if (res.status == 200) {
    //       this.registeredhomeowners = res.totalCount


    //     }
    //   })
    // }
  }
}
