<app-layout></app-layout>
<div class="content">
    <div style="display: flex; justify-content: space-between; margin-bottom: 15px;">
        <div style="width: 45%; background: linear-gradient(to bottom, rgba(23, 186, 174, 0.4), rgba(41, 234, 165, 0.4)); padding: 20px; border-radius: 8px; color: #fff; height: 150px; margin-top: 40px; display: flex; flex-direction: column; justify-content: center; align-items: center; border: 1px solid #22d6a9;">
            <h3 style="font-weight: bold; margin-bottom: 5px;color: black;">Total Number of Registered Organizers</h3>
            <p style="font-weight: bold;color: black;">{{registeredOrganisers}}</p>
        </div>
        <div style="width: 45%; background: linear-gradient(to bottom, rgba(23, 186, 174, 0.4), rgba(41, 234, 165, 0.4)); padding: 20px; border-radius: 8px; color: #fff; height: 150px; margin-top: 40px; display: flex; flex-direction: column; justify-content: center; align-items: center; border: 1px solid #22d6a9;">
            <h3 style="font-weight: bold; margin-bottom: 5px;color: black;">Total Number of Registered Service Providers </h3>
            <p style="font-weight: bold;color: black;">{{registeredProviders}}</p>
        </div>
    </div>
    
</div>
