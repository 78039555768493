import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { HeaderService } from "../services/header.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {

  manageCaregiversHeading: boolean = false;
  manageClientHeading:boolean = false;
  manageCategoryHeading:boolean = false;
  manageServicesHeading: boolean = false;
  manageTutorialHeading:boolean = false;
  manageConvenienceHeading:boolean = false;
  manageDiseasesHeading:boolean = false;
  manageOrganiserHeading:boolean = false;
  generateHeading:boolean = false;
  texHeading:boolean = false;
  manageanalyticsviewHeading:boolean = false;
  managedeletedHeading:boolean = false;
  manageTasksHeading:boolean = false;
  manageSubCategoriesHeading:boolean = false;
  manageLocalHeading:boolean = false;
  managePreventiveHeading:boolean = false;
  manageTasksViewHeading:boolean = false;
  manageplayoffHeading:boolean = false;
  




  welcomeUserMsg: boolean = false;
  hospitalHeading: boolean = false;
  loginHeading: boolean = false;
  usageHeading: boolean = false;
  supportHeading: boolean = false;
  feedbackHeading: boolean = false;
  manageHeading: boolean = false;
  aboutHeading:boolean = false;
  dashboardHeading:boolean = false;
  changePasswordHeading:boolean = false;
  pushNotificationHeading: boolean = false;
  addUserHeading: boolean = false;
  privacyPolicyHeading: boolean = false;
  surveyAnswersHeading: boolean = false;





  importedEventsHeading: boolean = false;
  subEventsHeading: boolean = false;
  zoneHeading: boolean = false;
  subZoneHeading: boolean = false;
  venueHeading: boolean = false;
  interestHeading: boolean = false;
  pointHeading: boolean = false;
  placeHeading: boolean = false;
  merchandiseHeading: boolean = false;
  sectionHeading: boolean = false;
  categoryHeading: boolean = false;
  pointcategoryHeading: boolean = false;
  homeScreenPartnersHeading: boolean = false;
  eventsponsorsHeading: boolean = false;
  notificationHeading: boolean = false;
  protocolHeading: boolean = false;
  seasonsHeading: boolean = false;
  chatListHeading: boolean = false;
  chatDetailsHeading: boolean = false;
  requestHeading: boolean = false;
  settingHeading: boolean = false;
  restaurantsHeading: boolean = false;
  requestsHeading: boolean = false;
  customerHeading: boolean = false;
  zoneRequestHeading: boolean = false;
  zoneChatHeading: boolean = false;
  zoneCallHeading: boolean = false;
  zoneReportingHeading: boolean = false;
  EventRequestHeading: boolean = false;
  BannerHeading: boolean = false;
  CuisineHeading: boolean = false;
  PopupHeading: boolean = false;
  PdfHeading: boolean = false;
  TreasureHeading: boolean = false;
  CouponHeading: boolean = false;
  ZoneCoordinatesHeading: boolean = false;
  SubZoneCoordinatesHeading: boolean = false;
  emailSupportHeading:boolean = false;

  default: boolean;
  lang: string;

  private returnUrl: any;

  profilePic: string = "";
  modalRef: any;

  constructor(
    private _header: HeaderService,
    public router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toAsterService: ToastrService
  ) {


    


    this._header.manageCaregiversHeading.subscribe((res) => {
      this.manageCaregiversHeading = res;
    });

    this._header.manageOrganiserHeading.subscribe((res) => {
      this.manageOrganiserHeading = res;
    });

    this._header.generateHeading.subscribe((res) => {
      this.generateHeading = res;
    });
    
    
    this._header.manageCategoryHeading.subscribe((res) => {
      this.manageCategoryHeading = res;
    });
    this._header.manageLocalHeading.subscribe((res) => {
      this.manageLocalHeading = res;
    });
    this._header.manageTasksViewHeading.subscribe((res) => {
      this.manageTasksViewHeading = res;
    });
    
    this._header.manageanalyticsviewHeading.subscribe((res) => {
      this.manageanalyticsviewHeading = res;
    });
    this._header.managedeletedHeading.subscribe((res) => {
      this.managedeletedHeading = res;
    });
     this._header.manageplayoffHeading.subscribe((res) => {
      this.manageplayoffHeading = res;
    });
   
    
    

    this._header.manageSubCategoriesHeading.subscribe((res) => {
      this.manageSubCategoriesHeading = res;
    });

    this._header.manageTasksHeading.subscribe((res) => {
      this.manageTasksHeading = res;
    });

    this._header.managePreventiveHeading.subscribe((res) => {
      this.managePreventiveHeading = res;
    });

    
    


    this._header.manageClientHeading.subscribe((res) => {
      this.manageClientHeading = res;
    });


    this._header.manageServicesHeading.subscribe((res) => {
      this.manageServicesHeading = res;
    });

    this._header.manageTutorialHeading.subscribe((res) => {
      this.manageTutorialHeading = res;
    });


    this._header.manageConvenienceHeading.subscribe((res) => {
      this.manageConvenienceHeading = res;
    });


    this._header.manageDiseasesHeading.subscribe((res) => {
      this.manageDiseasesHeading = res;
    });



  

    this._header.welcomeUserMsg.subscribe((res) => {
      this.welcomeUserMsg = res;
    });


    this._header.dashboardHeading.subscribe((res) => {
      this.dashboardHeading = res;
    });


    this._header.changePasswordHeading.subscribe((res) => {
      this.changePasswordHeading = res;
    });

    this._header.texHeading.subscribe((res) => {
      this.texHeading = res;
    });

 
  }

  userName: string;
  firstName:string;
  lastName: string;
  allDate:any;


  currentUser:any;
  ngOnInit(): void {
    
    this.currentUser = this.authService.currentUserValue;
    this.default = true;
    this.lang = "English";
    this.userName = "Admin";
    this.currentUser = this.authService.currentUserValue;
    this.profilePic = "../../../assets/icons/user-img.svg";

    this.allDate = this.authService.currentUserValue;
    this.userName = this.allDate?.firstName?.toUpperCase();

  }

  logout() {
    this.modalRef.hide();
    
    // this.authService.logout({email:this.currentUser.email, userType: 1}).subscribe((res:any) =>{
    //   console.log(res.status);
    //   if(res.status == 200){
    //     localStorage.removeItem("currentUser");
    //   }
      // else{
      //   this.toAsterService.error(res.error.message);
      // }
      
    // });
    this.router.navigateByUrl("/auth/login");
  }

  // modal lougout confirmation
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  confirm() {
    this.logout();
  }
  cancel() {
    this.modalRef.hide();
  }
}
